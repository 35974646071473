<script>
import { Tree, Checkbox } from 'ant-design-vue'
import api from '@/command/api'

let checkoutAllData = []

function getChildren(children) {
  return children.map(e => {
    checkoutAllData.push(e.id)
    return {
      ...e,
      key: e.id,
      title: e.menuName,
      children: e.children ? getChildren(e.children) : null
    }
  })
}

export default {
  props: {
    treeData: {
      type: Array,
      default: () => {
        return []
      }
    },
    cumTreeData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      ...api.command.getState(),
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      treeDataPaser: []
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.treeDataPaser = this.treeData.map(e => {
      checkoutAllData.push(e.id)
      return {
        ...e,
        key: e.id,
        title: e.menuName,
        children: e.children ? getChildren(e.children) : null
      }
    })

    this.checkedKeys = this.cumTreeData
  },
  methods: {
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys.checked
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    sumbit() {
      this.$emit('success', this.checkedKeys)
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`)
      this.checkedKeys = []
      if (e.target.checked) {
        this.checkedKeys = checkoutAllData
      }
    }
  },
  render() {
    return (
      <div>
        <Checkbox style="margin-left: 8px" onChange={this.onChange}>
          全选
        </Checkbox>
        <Tree
          v-model={this.checkedKeys}
          checkable={true}
          checkStrictly={true}
          expanded-keys={this.expandedKeys}
          auto-expand-parent={this.autoExpandParent}
          selected-keys={this.selectedKeys}
          tree-data={this.treeDataPaser}
          onCheck={this.onCheck}
          onExpand={this.onExpand}
          onSelect={this.onSelect}
        />
      </div>
    )
  }
}
</script>
